<template>
  <app-container
      v-if="!isTextLoading"
      :icon="texts.icon"
      colWidth="col-12 col-lg-6"
  >
    <template #header>
      <h1 class="text-dark mb-4">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="tokens">
        <p class="mb-4 text-start" v-html="texts.content"></p>
        <button class="btn" @click="validateEmail">
          {{ texts.button }}
        </button>
      </div>
    </template>
  </app-container>
</template>

<script>
import Database from '@/utils/services/Database';
import useTexts from '@/mixins/useTexts';

export default {
  name: 'EmailPage',
  mixins: [useTexts],
  data() {
    return {
      page: 'verify_email',
    };
  },
  methods: {
    async validateEmail() {
      try {
        const token = this.$route.params.token;
        await Database.create('actions', {
          token: token,
          type: 'EMAIL_VERIFIED',
        });

        this.$router.push({
          name: 'Success',
          params: {path: 'confirmation-du-compte'},
        });
      } catch (error) {
        this.$router.push({
          name: 'Error',
          params: {path: 'confirmation-du-compte'},
        });
      }
    },
  },
};
</script>

<style lang="scss">
.email-row {
  margin-top: $sp-3x;
}
</style>
